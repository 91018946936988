import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout";
import Slider from "react-slick";
import SliderSettings from "../components/SliderSettings";
import Banner from "../components/Banner";
import TitleDescriptionBlock from "../components/TitleDescriptionBlock";
import IconTexts from "../components/IconTexts";
import Separator from "../components/Separator";
import ImageTextBox from "../components/ImageTextBox";
import Gallery from "../components/Gallery";
import {Contact} from "../components/Contact";
import SEO from "../components/SEO";

class TemplateOffer extends Component {

    render() {
        const currentPage = this.props.data.wordpressPage;
        console.log(currentPage)
        return (
            <Layout>
                <SEO title={currentPage.yoast_meta.yoast_wpseo_title}
                     description={currentPage.yoast_meta.yoast_wpseo_metadesc}
                />
                <Slider {...SliderSettings}>
                    {currentPage.acf.slider.map((slide, index) => {
                        return (
                            <Banner
                                key={slide.default.title + "_" + index}
                                title={slide.default.title}
                                description={slide.default.description}
                                image={slide.default.image}
                            />
                        );
                    })}
                </Slider>

                <TitleDescriptionBlock title={currentPage.acf.intro.title} description={currentPage.acf.intro.description} style={{marginBottom: '5em'}} />

                <IconTexts items={currentPage.acf.icontexts} />

                <div style={{marginTop: '10em'}}>
                    <Separator dark title="Milon Fitnesszirkel" />
                </div>
                {currentPage.acf.zirkel.boxes.map((box, index) => {
                    return (
                        <ImageTextBox
                            key={`${box.default.title}_${index}`}
                            imageLeft={index % 2}
                            image={box.default.image}
                            title={box.default.title}
                            description={box.default.description}
                        />
                    );
                })}

                <Separator dark title="Five Beweglichkeits-Konzept" />

                {currentPage.acf.five_boxes.map((box, index) => {
                    return (
                        <ImageTextBox
                            key={`${box.default.title}_${index}`}
                            imageLeft={index % 2}
                            image={box.default.image}
                            title={box.default.title}
                            description={box.default.description}
                        />
                    );
                })}

                <Contact contact={currentPage.acf.contact} />

                <Separator title="Galerie" dark />
                <Gallery images={currentPage.acf.gallery} style={{padding: '1em'}}/>

            </Layout>
        )
    }
}

export default TemplateOffer;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            title
            date(formatString: "MMMM DD, YYYY")
            ...seo
            acf {
                ...sliderFragment
                ...introFragment
                ...iconTexts
                ...gallery
                ...contact
                zirkel {
                    boxes {
                        default {
                            title
                            description
                            link
                            link_text
                            image {
                                alt_text
                                title
                                description
                                localFile {
                                    childImageSharp {
                                        id
                                        fluid {
                                        base64
                                        tracedSVG
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        originalName
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                five_boxes {
                    default {
                        title
                        description
                        link
                        link_text
                        image {
                            alt_text
                            title
                            description
                            localFile {
                                childImageSharp {
                                    id
                                    fluid {
                                        base64
                                        tracedSVG
                                        aspectRatio
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                        originalName
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`